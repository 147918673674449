import axios from "axios";
import qs from "qs";
import clean from "~/utils/clean";

/* Add a response interceptor */
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);

    if (error.config.headers["axios-to-fetch"] === 1) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const response = new Response(JSON.stringify(error.response.data), {
          status: error.response.status,
          statusText: error.response.statusText,
          headers: error.response.headers,
        });

        return Promise.reject(response);
      } else if (error.request) {
        // The request was made but no response was received
        // error.request is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        return Promise.reject(error);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return Promise.reject(error);
      }
    } else return Promise.reject(error);
  },
);

const createAuthorizationHeader = (token) => {
  if (token) return { Authorization: `Bearer ${token}` };
  else return {};
};

export const APP_API_URL =
  process?.env?.APP_API_URL ||
  window?.process?.env?.APP_API_URL ||
  "https://a.hidsim.com";

const defaultHeaders = { AXIOS_TO_FETCH: 1 };
const instance = axios.create({ baseURL: APP_API_URL });

export const getSchema = ({ resource, token }) =>
  axios.get(`${APP_API_URL}/${resource}/schema`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const getListResources = ({
  resource,
  query,
  token,
  headers = { "axios-to-fetch": 1 },
}) => {
  const stringifiedQs =
    typeof query === "string" ? query : qs.stringify(clean(query));
  if (query?.text)
    return axios.get(`${APP_API_URL}/${resource}/search/?${stringifiedQs}`, {
      headers: { ...headers, ...createAuthorizationHeader(token) },
    });
  return axios.get(`${APP_API_URL}/${resource}/?${stringifiedQs}`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });
};

export const getResource = ({
  resource,
  resourceId,
  token,
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.get(`${APP_API_URL}/${resource}/${resourceId}`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const getLookupResource = ({
  resource,
  query,
  token,
  headers = { "axios-to-fetch": 1 },
}) => {
  const stringifiedQs = qs.stringify(query);
  return axios.get(`${APP_API_URL}/${resource}/lookup/?${stringifiedQs}`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });
};

export const patchResource = ({
  resource,
  resourceId,
  data,
  token,
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.patch(`${APP_API_URL}/${resource}/${resourceId}`, data, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const deleteResource = ({
  resource,
  resourceId,
  token,
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.delete(`${APP_API_URL}/${resource}/${resourceId}`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const getRenew = ({ token, headers = { "axios-to-fetch": 1 } }) =>
  axios.get(`${APP_API_URL}/auth/renew`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const getMe = ({ token, headers = { "axios-to-fetch": 1 } }) =>
  axios.get(`${APP_API_URL}/auth/me`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const postLog = ({
  token,
  data = {},
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.post(`${APP_API_URL}/auth/log`, data, {
    headers: {
      ...headers,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...createAuthorizationHeader(token),
    },
  });

export const postRegister = ({ data, headers = { "axios-to-fetch": 1 } }) =>
  axios.post(`${APP_API_URL}/auth/register`, data, {
    headers,
  });

export const postLogin = ({ data, headers = { "axios-to-fetch": 1 } }) =>
  axios.post(`${APP_API_URL}/auth/login`, data, { headers });

export const postPurchaseProduct = ({
  _productId,
  token,
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.post(
    `${APP_API_URL}/products/${_productId}/purchase`,
    {},
    {
      headers: { ...headers, ...createAuthorizationHeader(token) },
    },
  );

export const postPurchaseService = ({
  _serviceId,
  data,
  token,
  headers = { "axios-to-fetch": 1 },
}: {
  _serviceId: string;
  token?: string;
  headers?: { [key: string]: any };
}) =>
  axios.post(`${APP_API_URL}/services/${_serviceId}/purchase`, data, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const postPay = ({
  data,
  query,
  token,
  headers = { "axios-to-fetch": 1 },
}) => {
  const stringifiedQs = qs.stringify(query);
  return axios.post(`${APP_API_URL}/pay/?${stringifiedQs}`, data, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });
};

export const getCloseOperation = ({
  _operationId,
  token,
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.get(`${APP_API_URL}/operations/${_operationId}/close`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const getReviseOperation = ({
  _operationId,
  token,
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.get(`${APP_API_URL}/operations/${_operationId}/revise`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const getRefetchOperation = ({
  _operationId,
  token,
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.get(`${APP_API_URL}/operations/${_operationId}/refetch`, {
    headers: { ...headers, ...createAuthorizationHeader(token) },
  });

export const postRevokeSession = ({
  _sessionId,
  token,
  headers = { "axios-to-fetch": 1 },
}) =>
  axios.post(
    `${APP_API_URL}/sessions/${_sessionId}/revoke`,
    {},
    {
      headers: { ...headers, ...createAuthorizationHeader(token) },
    },
  );

export const postSendOtp = ({ data, headers }) =>
  instance.post(`/auth/otp:send`, data, { headers });

export const postCheckOtp = ({ data, headers }) =>
  instance.post(`/auth/otp:check`, data, { headers });

export const postResetPassword = ({ data, headers }) => instance.post(`/auth/password:reset`, data, { headers });
